import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import MainHero from "../components/mainHero";

const IndexPage = ({ data: { heroImage } }) => {
  const renderButton = () => (
    <Link
      to="/relaxation"
      className="button is-success is-large-tablet is-medium is-rounded"
    >
      Try it!
    </Link>
  );

  return (
    <Layout>
      <MainHero
        heroImage={heroImage}
        title1="Help Your Dog"
        title2="Find Their Calm"
        subTitle="An easy way to do the relaxation protocol"
        button={renderButton()}
        // ctaText="It's free"
      />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "woman_kissing_dog_2.jpg" }) {
      ...HeroImageFragment
    }
  }
`;
