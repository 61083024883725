import React from "react";

import * as heroStyles from "./mainHero.module.scss";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const MainHero = ({ title1, title2, subTitle, heroImage, button, ctaText }) => {
  return (
    <section
      className="hero is-fullheight-with-navbar"
      style={{ position: "relative" }}
    >
      <Img
        fluid={heroImage.childImageSharp.fluid}
        className={heroStyles.image}
        style={{
          position: "absolute",
        }}
      />
      <div className={`hero-body ${heroStyles.heroBody}`}>
        <div className="container">
          <div>
            <h1 className={`${heroStyles.heroTitle}`}>{title1}</h1>
            <h1 className={`${heroStyles.heroTitle}`}>{title2}</h1>
            <h2
              className={`${heroStyles.heroSubTitle} has-text-success`}
              style={{ paddingBottom: "2rem" }}
            >
              {subTitle}
            </h2>
            {/* <h2 className={`${heroStyles.heroParagraph}`}>
              A guided training app that walks you through daily challenges to
              teach your dog how to chill
            </h2> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "150px",
              }}
            >
              {button && button}
              <p
                className="has-text-centered is-size-5"
                style={{ marginTop: "0.6rem" }}
              >
                {ctaText && ctaText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainHero;

export const query = graphql`
  fragment HeroImageFragment on File {
    childImageSharp {
      fluid(maxHeight: 1200, cropFocus: WEST) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
